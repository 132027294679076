import Pusher from "pusher-js";

let API_URL = null
let SIGN_API_URL = null
let constantIds = null
let seamingUrl = null
let pusher = null

if (window.location.host === "dev.s10drd.com") {
	// Dev api urls
	API_URL = "https://dev.api.s10drd.com";
	SIGN_API_URL = "https://dev.api.s10drd.com";
	// For Odor B Gone
	constantIds = [520];
	// For seaming url
	seamingUrl = "dev.seaming.s10drd.com";
	// pusher credentials
	let PUSHER_KEY = "PLACEHOLDER_PROD_PUSHER_PUBLIC_KEY";
	let PUSHER_CLUSTER = "PLACEHOLDER_PROD_PUSHER_CLUSTER_ID";
	pusher = new Pusher(PUSHER_KEY, {
		cluster: PUSHER_CLUSTER,
		encrypted: true
	});
}

if (window.location.host === "stage.s10drd.com") {
	// Staging api urls
	API_URL = "https://stage.api.s10drd.com";
	SIGN_API_URL = "https://stage.api.s10drd.com";
	// For Odor B Gone
	constantIds = [885];
	// For seaming url
	seamingUrl = "stage.seaming.s10drd.com";
	// pusher credentials
	let PUSHER_KEY = "ec4cf71a74f6ae02e2df";
	let PUSHER_CLUSTER = "mt1";
	pusher = new Pusher(PUSHER_KEY, {
		cluster: PUSHER_CLUSTER,
		encrypted: true
	});
}

if (window.location.host === "blue.s10drd.com") {
	// Blue api urls
	API_URL = "https://blue.api.s10drd.com";
	SIGN_API_URL = "https://blue.api.s10drd.com";
	// For Odor B Gone
	constantIds = [885];
	// For seaming url
	seamingUrl = "blue.seaming.s10drd.com";
	// pusher credentials
	let PUSHER_KEY = "PLACEHOLDER_BLUE_PUSHER_PUBLIC_KEY";
	let PUSHER_CLUSTER = "PLACEHOLDER_BLUE_PUSHER_CLUSTER_ID";
	pusher = new Pusher(PUSHER_KEY, {
		cluster: PUSHER_CLUSTER,
		encrypted: true
	});
}

if (window.location.host === "red.s10drd.com") {
	// Red api urls
	API_URL = "https://red.api.s10drd.com";
	SIGN_API_URL = "https://red.api.s10drd.com";
	// For Odor B Gone
	constantIds = [885];
	// For seaming url
	seamingUrl = "red.seaming.s10drd.com";
	// pusher credentials 
	let PUSHER_KEY = "PLACEHOLDER_RED_PUSHER_PUBLIC_KEY";
	let PUSHER_CLUSTER = "PLACEHOLDER_RED_PUSHER_CLUSTER_ID";
	pusher = new Pusher(PUSHER_KEY, {
		cluster: PUSHER_CLUSTER,
		encrypted: true
	});
}

export { API_URL, SIGN_API_URL, constantIds, seamingUrl, pusher };
